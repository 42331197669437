<template>
  <div>
    <component :is="tem_type"></component>
  </div>
</template>
<script>
import Init from "./vip_template/init_tem";
import YangHua from "./vip_template/yanghua_tem";
import JingBiao from "./vip_template/jingbiao_tem";
export default {
  components: {
    Init, YangHua, JingBiao
  },
  data() {
    return {
      tem_type: "",
      arr_1: [430, 437, 439, 440], // 央化
      arr_2: [427, 432]            // 京标
    }
  },
  created() {
    this.arr_1.map(item => {
      if (this.appid == item) this.tem_type = "YangHua";
    })
    this.arr_2.map(item => {
      if (this.appid == item) this.tem_type = "JingBiao";
    })
    if (this.tem_type == "") this.tem_type = "Init";
  }
}
</script>
