<template>
  <div class="vip_mycenter">
    <nav-bar title="会员中心" v-if="isWeixin_status == false" />

    <div class="mycenter-cont">
      <vipCardList :showShopList="showShopList" :shopName="shopName"></vipCardList>

      <div class="mycenterCont">
        <div class="oil-newprice" v-if="showShopList">
          <div class="oil-newprice-top">
            <div class="newprice-top-tite">
              <span class="newprice-top-address">{{shopArea}}</span>
              <div @click="showPicker = true">切换店铺</div>
            </div>
            <div class="vip-enjoy">会员专享</div>
          </div>
          <div class="oil-newprice-bottom" v-if="isShowPice">
            <div class="oil-img">
              <img src="@static/vip/oil_logo.png" alt />
              <span>实时油价</span>
            </div>
            <div class="time-price">
              <div v-for="(item, index) in todayprice" :key="index">
                <span>{{item.name}}</span>
                <span>{{item.price}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="My-service">
          <div class="My-service-title">我的服务</div>
          <div class="My-service-options">
            <template v-for="(item, index) in service">
              <div class="options" :key="index" @click="skipPage(item)" v-show="item.status">
                <img :src="item.imgurl" alt />
                <div>{{item.title}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <copyright v-if="appid != 433"/>
    </div>

    <popup v-model="showPicker" position="bottom">
      <picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import vipCardList from "@components/public/vipCardList";
import { NavBar, Popup, Picker } from "vant";
import CreditShop from "@/api/creditshop";
import UserInfo from "@/api/user";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { getDistance } from "@/utils/tools";
import Config from "@/api/config";
import Setting from '@/api/setting'
import washCar from "@api/washCar"
import copyright from "@components/public/copyright";
export default {
  components: {
    NavBar,
    Popup,
    Picker,
    vipCardList,
    copyright
  },
  filters: {
    changDis: function(val) {
      if (val) {
        if (val == 100) {
          return "无";
        } else {
          return val / 10 + "折";
        }
      } else {
        return "无";
      }
    }
  },
  data() {
    return {
      isvipCardMore: true,
      showPicker: false,
      isShowPice: true,
      columns: [],
      latitude: "",
      longitude: "",
      todayprice: [
        {
          name: "0#",
          price: "7.66"
        },
        {
          name: "95#",
          price: "5.22"
        },
        {
          name: "92#",
          price: "3.44"
        }
      ],
      service: [
        {
          imgurl: require("@static/vip/maidan.png"),
          title: "买单",
          link: "",
          name: "buy_bill",
          status: true
        },
        {
          name: "coupon_center",
          imgurl: require("@static/vip/youhui.png"),
          title: "领劵中心",
          link: "/couponCenter",
          status: true
        },
        {
          name: "room_nav",
          imgurl: require("@static/vip/youzhandaohang.png"),
          title: "油站导航",
          link: "/vipShopList",
          status: true
        },
        {
          name: "pay_config",
          imgurl: require("@static/vip/zhifuguanli.png"),
          title: "支付管理",
          link: "/userSafety",
          status: true
        },
        {
          name: "recharge",
          imgurl: require("@static/vip/chongzhi.png"),
          title: "充值有礼",
          link: "/oilsCardList",
          status: true
        },
        // {
        //   name: "point_shop",
        //   imgurl: require("@static/vip/huiyuan.png"),
        //   title: "积分商城",
        //   link: "/integralshop",
        //   status: true
        // },
        {
          name: "point_shop",
          imgurl: require("@static/home/jifenduihuan.jpg"),
          title: "积分兑换",
          link: "/integralshop",
          status: true
        },
        {
          name: "team_card",
          imgurl: require("@static/vip/carTime.png"),
          title: "车队卡",
          link: "/carteamCard",
          status: true
        },
        {
          name: "family_card",
          imgurl: require("@static/vip/familyCard.png"),
          title: "亲属卡",
          link: "/kinsfolkCardList",
          status: true
        },
        {
          name: "invitation",
          title: "邀请赚钱",
          imgurl: require("@static/vip/jiagoufenxiao.png"),
          link: "/dis_entry",
          status: true
        },
        {
          name: "big_wheel",
          title: "大转盘",
          imgurl: require("@static/vip/zhuanpan.png"),
          link: "/turntable?entry_type=1",
          status: true
        },
        {
          title: "洗车服务",
          name: "wash_car",
          imgurl: require("@static/vip/wash car.png"),
          link: "/washCar?status=1",
          status: true
        }
      ],
      marketing: [
        {
          imgurl: require("@static/vip/tuijianyoujiang.png"),
          name: "推荐有奖",
          link: "/shareLink"
        },
        {
          imgurl: require("@static/vip/xianshituangou.png"),
          name: "限时团购",
          link: ""
        },
        {
          imgurl: require("@static/vip/huodongzhongxin.png"),
          name: "活动中心",
          link: "/shareLink"
        },
        {
          imgurl: require("@static/vip/jifenduihuan.png"),
          name: "积分兑换",
          link: "/integralshop"
        }
      ],
      name: "",
      headimgurl: "",
      coponNUm: "", //未使用优惠券数量
      shopList: [],
      my_cardInfor: {
        card: {}
      },
      page_status: false,
      showShopList: {},
      shopArea: "",
      shopName: "",
      my_msg: "定位中~"
    };
  },
  created() {
    if (this.isWeixin_status) {
      goWxConfigJsApi("hideMenuItems");
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: ['menuItem:copyUrl', 'menuItem:share:timeline', 'menuItem:share:appMessage'] 
        });
      }, 500);
      if (!sessionStorage.getItem("time")) {
        // 微信配置
        goWxConfigJsApi("openLocation,getLocation");
        setTimeout(() => {
          this.getInfo();
        }, 500);
      } else {
        if (sessionStorage.getItem("now_store")) {
          let obj = JSON.parse(sessionStorage.getItem("now_store"));
          let res = JSON.parse(sessionStorage.getItem("store_all"));
          this.showShopList = obj; //获取第一个店铺
          this.shopArea = obj.area; // 店铺地址
          this.shopName = obj.name; // 店铺名称
          this.initStoreInfo(res);
        } else {
          let res = JSON.parse(sessionStorage.getItem("store_all"));
          this.showShopList = res[0]; //获取第一个店铺
          this.shopArea = res[0].area; // 店铺地址
          this.shopName = res[0].name; // 店铺名称
          this.initStoreInfo(res);
        }
      }
    } else {
      this.getH5Info();
    }
  },
  async mounted() {
    this.isOpenShowPrice()
    // 功能配置信息
    const configArr = await this.getAppConfig()
    for (let i in this.service) {
      for (let y in configArr) {
        if (this.service[i].name == configArr[y].name) {
          this.service[i].status = configArr[y].status;
        }
      }
    }
  },
  methods: {
    // 判断是否开通大转盘
    async getConfig() {
      try {
        const res = await Setting.roundConfig();
        if (res.code == 200) {
          if (res.data.length == 0) {
            return false;
          } else {
            return true;
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async isOpenShowPrice() {
      const res = await Config.getWeiXinConfig('price_show')
      if (res.code === 200) {
        this.isShowPice = res.data.status
      }
    },
    // 是否开通洗车服务
    async getWashServe(){
      var  status = false;
      await washCar.getStoreList().then(res => {
        if(res.code == 200){
          if (res.data.result.length == 0) {
            status = false
          } else {
            status = true
          }
        }else{
          console.log(res);
        }
      });
      return status;
    },
    // 判断是否是分销商
    async isDistributor() {
      try {
        const res = await UserInfo.getSpreadSetInfo();
        if (!this.isWeixin_status) {
          this.$toast("请在微信浏览器中打开");
        } else {
          if (res.code == 200) {
            if (this.isWeixin_status) {
              this.$router.push("/invitation");
            } else {
              this.$toast("请在微信浏览器中打开");
            }
          } else {
            this.$router.push("/dis_entry");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async systemUpdate() {
      try {
        const res = await CreditShop.systemUpdate();
        if (res.type == 1) {
          if (res.app_id != localStorage.getItem("app_id")) {
            this.$router.replace("/beUpdating");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 计算两地之间距离
    setDistance(shop_arr) {
      var _this = this;
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          let arr = [];
          for (let i = 0; i < shop_arr.length; i++) {
            // 计算距离
            let item = shop_arr[i];
            item.dis = getDistance(
              item.location.lat,
              item.location.lng,
              res.latitude,
              res.longitude
            );
            arr.push(item);
          }
          const list = _this.quickSort(arr);
          _this.shopList = list;
          if (sessionStorage.getItem("now_store")) {
            let nowObj = JSON.parse(sessionStorage.getItem("now_store"));
            _this.showShopList = nowObj; //获取第一个店铺
            _this.shopArea = nowObj.area; // 店铺地址
            _this.shopName = nowObj.name; // 店铺名称
            _this.todayprice = nowObj.goods.slice(0, 4); // 获取店铺前三油品
          } else {
            _this.showShopList = list[0]; //获取第一个店铺
            _this.shopArea = list[0].area; // 店铺地址
            _this.shopName = list[0].name; // 店铺名称
            _this.todayprice = list[0].goods.slice(0, 4); // 获取店铺前三油品
          }
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            _this.columns.push(element.name);
          }
          _this.$toast.clear();
          sessionStorage.setItem("store_all", JSON.stringify(list));
        },
        fail: function(err) {
          _this.getH5Info();
          _this.$toast.clear();
          console.log(err);
        },
        complete: function() {
          console.log("aaaa");
        }
      });
    },
    async getH5Info() {
      try {
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.showShopList = res.data[0]; //获取第一个店铺
            this.shopArea = res.data[0].area; // 店铺地址
            this.shopName = res.data[0].name; // 店铺名称
            this.initStoreInfo(res.data);
            sessionStorage.setItem("store_all", JSON.stringify(res.data));
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getInfo() {
      try {
        this.my_msg = "获取店铺信息~";
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            if (res.data.length == 1) {
              this.showShopList = res.data[0]; //获取第一个店铺
              this.shopArea = res.data[0].area; // 店铺地址
              this.shopName = res.data[0].name; // 店铺名称
              this.shopList = res.data;
              this.todayprice = res.data[0].goods.slice(0, 4); // 获取店铺前三油品
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                this.columns.push(element.name);
              }
              sessionStorage.setItem("time", new Date().getTime());
              sessionStorage.setItem("store_all", JSON.stringify(res.data));
              this.$toast.clear();
            } else {
              this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                message: this.my_msg,
                loadingType: "spinner",
                selector: "#custom-selector"
              });
              this.setDistance(res.data);
            }
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async skipPage(item) {
        const { app_id, id, store_id } = this.showShopList;
        const token= localStorage.getItem('access_token');
      if (item.title == "买单") {
        const url = "https://xzf.58zltc.com/cardpay"+
          "?room_id="+id+"&store_id="+store_id+"&app_id="+app_id+"&app_token="+token;
        window.location.href = url;
      } else if (item.title == "支付管理") {
        sessionStorage.setItem("path", `${this.$route.fullPath}`);
        sessionStorage.setItem("verifyType", "dealPassword");
        this.$router.push("/userSafety");
      } else if (item.title == "邀请赚钱") {
        return this.isDistributor();
      } else if (item.title == "大转盘") {
        let status = await this.getConfig();
        if (!status) return this.$toast('尚未开通大转盘,请耐心等待~');
        this.$router.push(item.link);
      }else if (item.title == "洗车服务") {
        let status = await this.getWashServe();
        if (!status) return this.$toast('尚未开通洗车服务,请耐心等待~');
        this.$router.push(item.link);
      } else if (item.title == "积分商城") {
        const url = "https://hyk.zltc.cc/"+"?app_id="+app_id+"&app_token="+token;
        window.location.href = url;
      } else {
        this.$router.push(item.link);
      }
    },
    async initStoreInfo(store_info) {
      try {
        this.shopList.push(...store_info);
        this.todayprice = store_info[0].goods.slice(0, 4); // 获取店铺前三油品
        for (let i = 0; i < store_info.length; i++) {
          const element = this.shopList[i];
          this.columns.push(element.name);
        }
      } catch (error) {
        console.log(error);
      }
    },
    onConfirm(item, index) {
      //切换店铺
      this.showPicker = false;
      this.showShopList = this.shopList[index];
      this.shopArea = this.shopList[index].area; // 店铺地址
      this.shopName = this.shopList[index].name; // 店铺名称
      this.todayprice = this.shopList[index].goods.slice(0, 4);
      localStorage.setItem("now_store", JSON.stringify(this.shopList[index]));
    },
  }
};
</script>

<style lang="scss" scoped>
.vip_mycenter {
  padding-bottom: 50px;
  .shao {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .mycenter-cont {
    min-height: calc(100vh - 50px);
    background: #edf0f4;
    overflow: auto;
    // padding-bottom: 55px;
    position: relative;
    .mycenterCont {
      padding: 12px;
      margin-top: -30px;
    }
    .oil-newprice {
      width: 100%;
      max-height: 130px;
      display: flex;
      background: #fff;
      border-radius: 6px;
      margin-top: 10px;
      flex-direction: column;
      .oil-newprice-top {
        display: flex;
        height: 50px;
        padding: 0 18px;
        align-items: center;
        justify-content: space-between;
        .vip-enjoy {
          color: #ffffff;
          padding: 0 10px;
          border-radius: 6px;
          text-align: center;
          background: linear-gradient(
            180deg,
            rgba(255, 210, 143, 1) 0%,
            rgba(255, 170, 76, 1) 100%
          );
        }
        .newprice-top-tite {
          font-size: 16px;
          color: #333333;
          font-weight: 560;
          display: flex;
          align-items: center;
          .newprice-top-address {
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          div {
            width: 48px;
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            margin-left: 10px;
            color: rgba(66, 116, 241, 1);
            line-height: 17px;
            border-bottom: 1px solid rgba(66, 116, 241, 1);
          }
        }
      }
      .oil-newprice-bottom {
        flex: 1;
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        .oil-img {
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          padding: 16px 0;
          img {
            width: 30px;
          }
        }
        .time-price {
          width: 100%;
          display: flex;
          margin-left: 7px;
          // justify-content: space-between;
          div {
            display: flex;
            padding: 16px 0;
            padding-top: 20px;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            margin: 0 7px;
            span:nth-child(1) {
              // min-width: 50px;
              padding: 0 10px;
              // padding: 1px 14px;
              height: 20px;
              text-align: center;
              line-height: 17px;
              height: 17px;
              background: rgba(242, 242, 242, 1);
              border-radius: 3px;
            }
            span:nth-child(2) {
              font-size: 18px;
              font-weight: bold;
              color: #ce100f;
              height: 18px;
              line-height: 18px;
            }
            span:nth-child(2)::before {
              content: "\A5";
              font-size: 10px;
            }
          }
        }
      }
    }
    .information {
      height: 178px;
      background: #fff;
      border-radius: 10px;

      .information-top {
        height: 105px;
        padding: 15px;
        display: flex;

        .information-head {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          overflow: hidden;
        }

        img {
          width: 100%;
          height: 100%;
        }

        .information-introduce {
          padding-top: 6px;
          margin-left: 10px;

          .introduce-name {
            font-size: 16px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            display: flex;
            margin-bottom: 5px;

            .changeShop {
              margin-left: 10px;
            }
          }

          .upgrade-hint {
            height: 24px;
            line-height: 24px;
            font-size: 10px;
            color: #999999;
          }

          .class-tags {
            display: flex;
            .class-img {
              width: 79px;
              height: 23px;
              background: url("../../../../public/static/images/vip/dengjibg.png");
              background-size: cover;
              padding-left: 28px;
              font-size: 10px;
              font-weight: bold;
              line-height: 23px;
              color: rgba(255, 255, 255, 1);
              margin-right: 5px;
            }

            .sign-tags {
              width: 52px;
              height: 23px;
              background: rgba(114, 175, 238, 1);
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #fff;
              padding: 0 7px;

              img {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }

      .information-line {
        width: 325px;
        margin: 0 auto;
        height: 1px;
        background: #eeeeee;
      }

      .infor-detils {
        display: flex;
        > div {
          flex: 1;
          height: 72px;
          padding: 17px 0 21px;
          text-align: center;

          .infor-detile-num {
            height: 20px;
            font-size: 18px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 5px;
            span {
              font-size: 14px;
              font-weight: 600;
              color: #ff6354;
            }
          }

          .infor-detils-text {
            font-size: 12px;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }

    .today-oil-price {
      height: 121px;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      margin-top: 10px;
      padding: 17px 13px;

      .title-img {
        img {
          width: 82px;
          height: 22px;
        }
      }

      .oil-price > div::after {
        width: 1px;
        height: 40px;
        background: #eeeeee;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
      }

      .oil-price > div:last-child::after {
        width: 0px;
      }
    }

    .My-service {
      height: auto;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      margin-top: 10px;
      padding: 20px 15px;
      position: relative;

      .My-service-title {
        font-size: 18px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
      .My-service-options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .options {
          width: 80px;
          height: 78px;
          padding-top: 23px;
          text-align: center;
          img {
            width: 30px;
            height: 30px;
          }
          div {
            font-size: 14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            margin-top: 5px;
          }
        }
      }
    }

    .my-swipes {
      margin-top: 10px;
      height: 100px;
      background: rgba(255, 255, 255, 1);
      opacity: 0.98;
      border-radius: 10px;
      overflow: hidden;
    }
    .marketing {
      height: 145px;
    }
  }

  .setPadding {
    padding-top: 210px;
  }
}
</style>
