<template>
  <div class="yanghua-con">
    <div class="yanghua-top-con">
      <div class="yanghua-top-con-center">
        <span class="yanghua-text">{{ storeInfo.name }}</span>
        <div class="yanghua-top-main">
          <span class="iconfont iconaddress"></span>
          <div class="yanghua-main-text">
            <span class="yanghua-store-title">{{ storeInfo.area }}{{ storeInfo.address }}</span>
            <span class="iconfont iconqiehuan-"></span>
            <span @click="goYouList">切换油站</span>
          </div>
        </div>
      </div>
    </div>
    <div class="yanghua-banner-con">
      <div class="yanghua-tehui">
        <div class="yanghua-tehui-left">
          <div>方便快捷，加油特惠</div>
          <div class="yanghua-store-title"></div>
          <div class="yanghua-sign-in" @click="go_sign_in">立即签到</div>
        </div>
        <div class="right-text">
          <div>中国央化</div>
          <img src="@static/vip/top_oiltext.png" />
        </div>
      </div>
      <div class="yanghua-store-info">
        <div class="yanghua-store-info-con">
          <div class="yanghua-oil-title">
            <span>实时油价</span>
            <img src="@static/vip/quishi.png" />
          </div>
          <div class="yanghua-logo-vip">
            <span>会员专享</span>
          </div>
        </div>
        <div class="yanghua-left-con">
          <div class="yanghua-left-con-item" v-for="(item, index) in storeInfo.goods" :key="index">
            <div class="yanghua-item-lable">{{ item.name }}</div>
            <div class="yanghua-item-lable-bot">
              <span>￥</span>
              <span>{{ item.price }}</span>
            </div>
            <div class="yanghua-line"></div>
          </div>
        </div>
        <!-- <div class="yanghua-fun-btn" @click="goUserPay">
          <div class="yanghua-fun-btn-item">
            <div>
              <img src="@static/vip/yanghua_add_oil.png" />
            </div>
            <span>快捷加油</span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="user-libao">
      <div>
        <div class="zhuce">
          注册即送 <span class="big-libao">新人大礼包</span>
        </div>
        <span class="lingqu">立即注册随机领取大礼包</span>
      </div>
      <div class="yanghua-flower">
        <img src="@static/vip/libao.png" />
      </div>
    </div>

    <div class="yanghua-serve-con">
      <div class="yanghua-my-title">我的服务</div>

      <div class="yanghua-home-arr">
        <template v-for="(item, index) in home_arr" >
          <div :key="index" class="yanghua-home-item" @click="skipPage(item)">
            <div class="yanghua-item-icon">
              <img :src="item.imgurl" />
            </div>
            <span>{{ item.name }}</span>
          </div>
        </template>
      </div>
    </div>
    <popup v-model="showPicker" position="bottom">
      <picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="setStore" />
    </popup>
    <div class="copyright">
      <div>油站帮会员管理系统</div>
      <div>
        <tag>
          <a class="tel-phone" href="tel:18618465123">电话:18618465123</a>
        </tag>
      </div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { Popup, Picker, tag } from "vant";
import CreditShop from "@/api/creditshop";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { getDistance } from "@/utils/tools";
import UserInfo from "@/api/user";
import Setting from '@/api/setting'
export default {
  components: {
     Popup, Picker, tag
  },
  data() {
    return {
      isvipCardMore: true,
      showPicker: false,
      columns: [],
      latitude: "",
      longitude: "",
      todayprice: [],
      storeInfo: {},
      shopList: [],
      page_status: false,
      my_msg: "定位中~",
      home_arr: [
        {
          imgurl: require("@static/vip/yanghua_1.png"),
          name: "领劵中心",
          link: "/couponCenter",
          stats: true
        },
        {
          imgurl: require("@static/vip/yanghua_2.png"),
          name: "油站导航",
          link: "/vipShopList",
          stats: true
        },
        {
          imgurl: require("@static/vip/yanghua_3.png"),
          name: "充值有礼",
          link: "/oilsCardList",
          stats: true
        },
        {
          imgurl: require("@static/vip/yanghua_4.png"),
          name: "积分商城",
          link: "/integralshop",
          stats: true
        },
        {
          imgurl: require("@static/vip/zhifuguanli.png"),
          name: "支付管理",
          link: "/userSafety",
          stats: true
        },
        {
          imgurl: require("@static/vip/carTime.png"),
          name: "车队卡",
          link: "/carteamCard",
          stats: true
        },
        {
          imgurl: require("@static/vip/familyCard.png"),
          name: "亲属卡",
          link: "/kinsfolkCardList",
          stats: true
        },
        {
          name: "邀请赚钱",
          imgurl: require("@static/vip/jiagoufenxiao.png"),
          link: "/dis_entry",
          stats: true
        },
        {
          name: "大转盘",
          imgurl: require("@static/vip/zhuanpan.png"),
          link: "/turntable?entry_type=1",
          stats: true
        }
      ],
    }
  },
  created() {
    if (this.isWeixin_status) {
      goWxConfigJsApi("hideMenuItems");
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: ['menuItem:copyUrl', 'menuItem:share:timeline', 'menuItem:share:appMessage'] 
        });
      }, 500);
      if (!sessionStorage.getItem('time')) {
        // 微信配置
        goWxConfigJsApi("openLocation,getLocation");
        setTimeout( () => {
          this.getInfo();
        }, 500)
      } else {
        let obj = JSON.parse(sessionStorage.getItem('now_store'));
        let res = JSON.parse(sessionStorage.getItem('store_all'));
        this.storeInfo = obj ? obj:res[0];
      }
    } else {
      this.getH5Info();
    }
  },
  methods: {
    goYouList() {
      this.$router.push('/vipShopList')
    },
    // 判断是否开通大转盘
    async getConfig() {
      try {
        const res = await Setting.roundConfig();
        if (res.code == 200) {
          if (res.data.length == 0) {
            return false;
          } else {
            return true;
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 判断是否是分销商
    async isDistributor() {
      try {
        const res = await UserInfo.getSpreadSetInfo();
        if (res.code == 200) {
          if (this.isWeixin_status) {
            this.$router.push("/invitation");
          } else {
            this.$toast('请在微信浏览器中打开');
          }
        } else {
          this.$router.push("/dis_entry");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async systemUpdate() {
      try {
        const res = await CreditShop.systemUpdate();
        if (res.type == 1) {
          if (res.app_id != localStorage.getItem("app_id")) {
            this.$router.replace("/beUpdating");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    setStore(item, index) {
      this.showPicker = false;
      this.storeInfo = this.shopList[index];
    },
    go_sign_in() {
      this.$router.push('/integralshop')
    },
    goUserPay() {
      const { app_id, id, store_id } = this.storeInfo;
      const token= localStorage.getItem('access_token');
      const url = "https://xzf.58zltc.com/cardpay"+
        "?room_id="+id+"&store_id="+store_id+"&app_id="+app_id+"&app_token="+token;
      window.location.href = url;
      // localStorage.removeItem("qrcode_id");
      // this.$router.push({
      //   path: "/cardpay",
      //   query: {
      //     type: 0,
      //     store_id: this.storeInfo.store_id,
      //     room_id: this.storeInfo.id
      //   }
      // });
    },
    // 计算两地之间距离
    setDistance(shop_arr) {
      var _this = this;
      sessionStorage.setItem('time', new Date().getTime());
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          let arr = [];
          for (let i = 0; i < shop_arr.length; i++) {
            // 计算距离
            let item = shop_arr[i];
            item.dis = getDistance(item.location.lat, item.location.lng, res.latitude, res.longitude);
            arr.push(item);
          }
          const list = _this.quickSort(arr);
          _this.shopList = list;
          _this.storeInfo = list[0];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            _this.columns.push(element.name);
          }
          _this.$toast.clear();
          sessionStorage.setItem('store_all', JSON.stringify(list));
        },
        fail: function(err) {
          _this.getH5Info();
          _this.$toast.clear();
          console.log(err);
        },
        complete: function() {
          console.log('aaaa')
        }
      });
    },
    async getH5Info() {
      try {
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.storeInfo = res.data[0];
            this.shopList = res.data;
            this.initStoreInfo(res.data);
            sessionStorage.setItem('store_all', JSON.stringify(res.data));
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getInfo() {
      try {
        this.my_msg = "获取店铺信息~"
        sessionStorage.setItem('time', new Date().getTime());
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            if(res.data.length == 1) {
              this.storeInfo = res.data[0];
              this.shopList = res.data;
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                this.columns.push(element.name);
              }
              this.$toast.clear();
              sessionStorage.setItem('store_all', JSON.stringify(res.data));
            } else {
              this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                message: this.my_msg,
                loadingType: 'spinner',
                selector: '#custom-selector',
              });
              this.setDistance(res.data);
            }
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async skipPage(item) {
      this.$router.push(item.link);
      if (item.name == "买单") {
        const { app_id, id, store_id } = this.storeInfo;
        const token= localStorage.getItem('access_token');
        const url = "https://xzf.58zltc.com/cardpay"+
          "?room_id="+id+"&store_id="+store_id+"&app_id="+app_id+"&app_token="+token;
        window.location.href = url;
      } else if (item.name == "支付管理") {
        sessionStorage.setItem("path", `${this.$route.fullPath}`);
        sessionStorage.setItem("verifyType", "dealPassword");
        this.$router.push("/userSafety");
      } else if (item.name == "大转盘") {
        let status = await this.getConfig();
        if (!status) return this.$toast('尚未开通大转盘,请耐心等待~');
        this.$router.push(item.link);
      } else if (item.name == "邀请赚钱") {
        return this.isDistributor();
      }
    },
    know() {
      window.location.href =
        "https://mp.weixin.qq.com/s/rKg5vOlsWvd_ZmMljjTJ0w";
    }
  }
};
</script>
<style scoped>
.yanghua-con {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.yanghua-top-con {
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  background-color: #023284;
}
.yanghua-store-title {
  width: 150px;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  margin-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.yanghua-top-con-center {
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
}
.yanghua-top-main {
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
}
.yanghua-main-text {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: rgba(252, 243, 230, 1);
  justify-content: space-between;
}
.yanghua-oil-title img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.yanghua-sign-in {
  width: 90px;
  height: 30px;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  margin-left: 10px;
  line-height: 30px;
  font-weight: 560;
  font-family: SourceHanSansCN;
  background: linear-gradient(
    0deg,
    rgba(236, 139, 24, 1),
    rgba(243, 183, 48, 1)
  );
  border-radius: 25px;
}
.yanghua-tehui {
  width: 94%;
  height: 150px;
  display: flex;
  color: #fff;
  margin: 0 auto;
  padding: 32px 0;
  justify-content: space-between;
  align-items: center;
}
.yanghua-tehui-left {
  display: flex;
  height: 100px;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  flex-direction: column;
}
.right-text {
  width: 180px;
  height: 121px;
  position: relative;
}
.right-text div {
  width: 180px;
  position: absolute;
  color: #313134;
  font-size: 12px;
  text-align: center;
  top: 4px;
  font-weight: 560;
}
.right-text img {
  width: 100%;
  height: 100%;
}
.yanghua-banner-con {
  width: 100%;
  height: 320px;
  position: relative;
  border-radius: 0px 0px 25px 25px;
  background-color: #023284;
}
.yanghua-text {
  font-size: 13.8px;
  font-family: SourceHanSansCN;
  font-weight: 500;
  color: rgba(252, 243, 230, 1);
  line-height: 7px;
}
.yanghua-swiper {
  width: 350px;
  margin: 0 auto;
}

.yanghua-swiper img {
  width: 100%;
  border-radius: 16rpx;
}

.yanghua-store-info {
  width: 342px;
  height: 160px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 20px auto 0;
  border-radius: 10px;
  position: relative;
  background: url("https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1590717277.png");
  background-size: cover;
}
.yanghua-store-info-con {
  width: 100%;
  height: 35px;
  display: flex;
  padding: 0 14px;
  align-items: center;
  justify-content: space-between;
}

.yanghua-home-arr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;
  justify-content: flex-start;
}
.yanghua-my-store {
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.yanghua-left-logo {
  width: 82px;
  height: 57px;
}
.yanghua-left-con {
  width: 100%;
  height: 76px;
  display: flex;
  margin-left: -10px;
  margin-top: 10px;
}

.yanghua-left-con-item {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  margin-left: 5.5px;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  /* border-right: 2rpx solid rgba(168,121,54,0.yanghua-4); */
  justify-content: space-between;
}
.yanghua-item-lable {
  padding: 3px 15px;
  background: #363131;
  display: inline-block;
  border-radius: 8px;
  font-size: 12px;
  color: #fff;
  margin-left: 7px;
}
.yanghua-item-lable-bot {
  color: #fff1db;
  font-weight: 560;
}
.yanghua-item-lable-bot span:nth-child(1) {
  font-size: 10px;
}
.yanghua-item-lable-bot span:nth-child(2) {
  font-size: 18px;
}

.yanghua-oil-title {
  font-size: 19px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(70, 41, 0, 1);
  line-height: 36.5px;
}
.yanghua-line {
  width: 1px;
  height: 41px;
  position: absolute;
  right: 0;
  top: 16px;
  background: rgba(168, 121, 54, 0yanghua-4);
}
.yanghua-line:last-child {
  display: none;
}

.yanghua-home-item {
  font-size: 13px;
  width: 22%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  margin: 5px;
  padding: 10px 5px;
  color: #8d8d8d;
  font-size: 11px;
}
.yanghua-logo-vip {
  width: 90px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  position: relative;
  color: #e2d29f;
  border-radius: 20px;
  background: rgba(89, 83, 83, 1);
}
.yanghua-logo-vip span {
  width: 100%;
  color: rgba(226, 210, 159, 1);
  z-index: 999;
  display: inline-block;
  text-align: center;
}

.yanghua-item-icon {
  width: 35px;
  height: 35px;
}
.yanghua-item-icon img {
  width: 100%;
  height: 100%;
}

.yanghua-fun-btn {
  width: 100%;
  padding: 0 31.5px;
  display: flex;
  height: 41px;
  align-items: center;
  justify-content: space-between;
}

.yanghua-fun-btn-item {
  width: 340px;
  display: flex;
  height: 45px;
  color: #be9b5f;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: #fff;
  margin-top: 6px;
  box-shadow: 0px 2px 2.25px 0px rgba(68, 103, 241, 0.27);
  border-radius: 20.5px;
}
.yanghua-fun-btn-item > div {
  width: 21px;
  height: 20px;
}
.yanghua-fun-btn-item > div > img {
  width: 100%;
  height: 100%;
}
.yanghua-fun-btn-item:nth-child(2) {
  background: linear-gradient(
    90deg,
    rgba(95, 124, 253, 1),
    rgba(62, 99, 239, 1)
  );
  box-shadow: 0px 4px 9px 0px rgba(58, 93, 255, 0.3);
}
.yanghua-fun-btn-item span {
  margin-left: 10px;
  font-size: 16px;
}

.yanghua-serve-con {
  width: 100%;
  height: auto;
  margin-top: -10px;
  padding-bottom: 10px;
  background-color: #fff;
}
.yanghua-my-title {
  margin-top: 20px;
  font-size: 17px;
  padding: 10px 23px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #313134;
}

.yanghua-my-title img {
  width: 20px;
  height: 17px;
  margin-left: 10px;
}

.page-bottom {
  width: 100%;
  height: 25px;
}

.user-libao {
  width: 100%;
  height: 160rpx;
  display: flex;
  padding: 0 20px;
  margin-top: 20px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
}
.zhuce {
  font-size: 17px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  color: rgba(48, 48, 48, 1);
  line-height: 7px;
  margin-top: 20px;
}
.big-libao {
  background: linear-gradient(
    267deg,
    rgba(248, 70, 54, 1) 0%,
    rgba(251, 121, 68, 1) 100%
  );
  -webkit-text-fill-color: transparent;
}
.lingqu {
  height: 40px;
  font-size: 12px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 20px;
  margin-top: 10px;
  display: block;
}
.yanghua-flower {
  width: 90px;
  height: 50px;
  margin-right: 20px;
}

.yanghua-flower img {
  width: 100%;
  height: 100%;
}
.copyright {
  width: 340px;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  position: relative;
  margin: 10px auto;
  color: #999999;
}
a {
  color: #fff;
}
</style>
