<template>
  <div>
    <div class="vipCardList">
      <div class="vipCard-more">
        <div class="sign-in">
          <span>{{ shopName }}</span>
          <div class="sign-in-tag" @click="$router.push('/checkin')">签到</div>
        </div>
        <div class="card-grade">
          <div class="Progress-bar">
            <Progress :percentage="percentages" stroke-width="1" :show-pivot="false" />
          </div>
          <div class="Progress-bar-title">
            <div v-for="(item, index) in cardList" :key="index">{{ item.name }}</div>
          </div>
        </div>
        <div class="vip-card-cont">
          <div class="vip-card-cont-opti">
            <span>{{ myInfo.wallet.balance }}</span>
            <span>余额</span>
          </div>
          <div class="vip-card-cont-opti">
            <span>{{ myInfo.wallet.point }}</span>
            <span>积分</span>
          </div>
          <div class="vip-card-cont-opti" @click="$router.push('/coupon')">
            <span>{{ myInfo.coupon }}</span>
            <span>优惠券</span>
          </div>
          <div class="vip-card-cont-opti" @click="$router.push('/oilsCardList')">
            <span>{{ myInfo.card }}</span>
            <span>加油卡</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/api/user";
import { Progress } from "vant";
export default {
  props: ["showShopList","shopName"],
  components: {
    Progress
  },
  data() {
    return {
      isvipCardMore: true,
      oilsNum: "",
      coponNUm: "", //未使用优惠券数量
      my_cardInfor: {
        card: {}
      },
      cardList: [],
      myInfo: {
        wallet: {
          point: "",
          balance: ""
        }
      },
      percentages: 20 //进度条长度
    };
  },
  created() {
    this.MygetVipList();
  },
  watch: {},
  methods: {
    // 获取会员列表
    async getCards() {
      try {
        const res = await UserInfo.getLevel_cards();
        if (res.code == 200) {
          this.cardList = res.data;
          // 设置进度条
          let duan = res.data.length - 1;
          if (duan==0) {
            this.percentages = 50;
          } else {
            let zanyong = Math.round(100 / duan);
            for (let i = 0; i < res.data.length; i++) {
              if (this.my_cardInfor.card.name == res.data[i].name) {
                if (zanyong * i + 5 > 100) {
                  this.percentages = 100;
                } else {
                  this.percentages = zanyong * i + 5;
                }
              }
            }
          }
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async getlist() {
      const res = await UserInfo.vipCardList();
      if (res.code == 200) {
        this.cardList = res.data;
        if (res.data.length > 0) {
          this.isvipCardMore = true;
          this.$parent.isvipCardMore = true;
        } else {
          this.isvipCardMore = false;
          this.$parent.isvipCardMore = false;
        }
        // 设置进度条
        let duan = res.data.length - 1;
        if (duan==0) {
          this.percentages = 50;
        } else {
          let zanyong = Math.round(100 / duan);
          for (let i = 0; i < res.data.length; i++) {
            if (this.my_cardInfor.card.name == res.data[i].name) {
              this.percentages = zanyong * i + 5;
            }
          }
        }
      }
    },
    // 获取我的会员卡信息
    async MygetVipList() {
      try {
        const res = await UserInfo.getUserMember();
        if (res.code == 200) {
          this.myInfo = res.data;
          this.my_cardInfor = res.data;
          localStorage.setItem("info_mobile", this.myInfo.mobile)
          localStorage.setItem("info_uid", this.myInfo.id)
          localStorage.setItem("headimgurl", this.myInfo.headimgurl)
          await this.getCards();
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.vipCardList {
  height: 224px;
  width: 100%;
  background: url("../../../public/static/images/vip/newvipbg.png");
  background-size: contain;
  background-repeat: no-repeat;
  // position: absolute;
  // top: 0;
  // left: 0;
  padding: 20px 14px;

  .vipCard-more {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    background: url("../../../public/static/images/vip/viplistbg.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 30px 25px 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-grade {
      .Progress-bar {
        display: flex;
        div {
          flex: 1;
        }
      }

      .Progress-bar-title {
        display: flex;
        margin-top: 8px;
        height: 16px;
        justify-content: space-between;
      }
    }

    .sign-in {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 24px;

      span {
        font-size: 18px;
      }

      .sign-in-tag {
        padding: 4px 14px;
        background: #555870;
        border-radius: 15px;
      }
    }

    .vip-card-cont {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;

      .vip-card-cont-opti {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        span:nth-child(1) {
          color: #dab979;
          text-align: center;
        }
      }
    }
  }
}
.information {
  height: 178px;
  background: #fff;
  border-radius: 10px;

  .information-top {
    height: 105px;
    padding: 15px;
    display: flex;

    .information-head {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .information-introduce {
      padding-top: 6px;
      margin-left: 10px;

      .introduce-name {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        display: flex;
        margin-bottom: 5px;

        .changeShop {
          margin-left: 10px;
        }
      }

      .upgrade-hint {
        height: 24px;
        line-height: 24px;
        font-size: 10px;
        color: #999999;
      }

      .class-tags {
        display: flex;
        .class-img {
          width: 79px;
          height: 23px;
          background: url("../../../public/static/images/vip/dengjibg.png");
          background-size: cover;
          padding-left: 28px;
          font-size: 10px;
          font-weight: bold;
          line-height: 23px;
          color: rgba(255, 255, 255, 1);
          margin-right: 5px;
        }

        .sign-tags {
          width: 52px;
          height: 23px;
          background: rgba(114, 175, 238, 1);
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          padding: 0 7px;

          img {
            width: 13px;
            height: 13px;
          }
        }
      }
    }
  }

  .information-line {
    width: 325px;
    margin: 0 auto;
    height: 1px;
    background: #eeeeee;
  }

  .infor-detils {
    display: flex;
    > div {
      flex: 1;
      height: 72px;
      padding: 17px 0 21px;
      text-align: center;

      .infor-detile-num {
        height: 20px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 5px;
        span {
          font-size: 14px;
          font-weight: 600;
          color: #ff6354;
        }
      }

      .infor-detils-text {
        font-size: 12px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>
