<template>
  <div class="jing-con">
    <div class="jingbiao-top-con">
      <div class="jingbiao-top-con-center">
        <span class="iconfont iconaddress"></span>
        <div class="jingbiao-main-text" @click="setStore">
          <span class="jingbiao-store-title">{{ storeInfo.area }}{{ storeInfo.address }}</span>
          <span class="iconfont conqiehuan-" style="color:'#fff', size:'22'"></span>
          <span>切换店铺</span>
        </div>
      </div>
      <div class="jingbiao-sign-in" @click="go_sign_in">立即签到</div>
    </div>
    <div class="jingbiao-banner-con">
      <div class="jingbiao-swiper">
        <div class="logo">
          <img src="@static/vip/jingbiao_logo.png" alt="">
        </div>
        <img src="@static/vip/banner_1.png" />
      </div>
    </div>

    <div class="jingbiao-store-info">
      <div class="jingbiao-store-info-con">
        <div class="jingbiao-oil-title">实时油价</div>
        <div class="jingbiao-logo-vip">
          <span>VIP会员专享</span>
        </div>
      </div>
      <div class="jingbiao-left-con">
        <div class="jingbiao-left-con-item" v-for="(item, idx ) in storeInfo.goods" :key="idx">
          <div class="jingbiao-item-lable">{{ item.name }}</div>
          <div class="jingbiao-item-lable-bot">
            <span>￥</span>
            <span>{{ item.price }}</span>
          </div>
          <div class="jingbiao-line"></div>
        </div>
      </div>
    </div>

    <div class="jingbiao-fun-btn">
      <div class="jingbiao-fun-btn-item" @click="goUserPay">
        <div class="">
          <img src="@static/vip/add_oil_icon.png" />
        </div>
        <span>快捷加油</span>
      </div>
    </div>

    <div class="jingbiao-serve-con">
      <div class="jingbiao-my-title">
        <img src="@static/vip/title_icon.png" />
        我的服务
      </div>

      <div class="jingbiao-home-arr">
        <div class="jingbiao-home-item" v-for="(item, idx)  in home_arr" :key="idx" @click="skipPage(item)">
          <div class="jingbiao-item-icon">
            <img :src="item.imgurl" />
          </div>
          <span>{{item.name}}</span>
        </div>
      </div>
    </div>

    <div class="page-bottom">
      <span>中国京标能源技术支持</span>
      <div>18765520591</div>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import CreditShop from "@/api/creditshop";
import { goWxConfigJsApi } from "@/utils/wxpay";
import { getDistance } from "@/utils/tools";
import UserInfo from "@/api/user";
import Setting from "@/api/setting";
export default {
  data() {
    return {
      isvipCardMore: true,
      showPicker: false,
      columns: [],
      latitude: "",
      longitude: "",
      todayprice: [],
      storeInfo: {},
      shopList: [],
      page_status: false,
      my_msg: "定位中~",
      home_arr: [
        {
          imgurl: require("@static/vip/serve_1.png"),
          name: "领劵中心",
          link: "/couponCenter",
          stats: true
        },
        {
          imgurl: require("@static/vip/serve_2.png"),
          name: "油站导航",
          link: "/vipShopList",
          stats: true
        },
        {
          imgurl: require("@static/vip/serve_3.png"),
          name: "充值有礼",
          link: "/oilsCardList",
          stats: true
        },
        {
          imgurl: require("@static/vip/serve_4.png"),
          name: "积分商城",
          link: "/integralshop",
          stats: true
        },
        {
          imgurl: require("@static/vip/zhifuguanli.png"),
          name: "支付管理",
          link: "/userSafety",
          stats: true
        },
        {
          imgurl: require("@static/vip/carTime.png"),
          name: "车队卡",
          link: "/carteamCard",
          stats: true
        },
        {
          imgurl: require("@static/vip/familyCard.png"),
          name: "亲属卡",
          link: "/kinsfolkCardList",
          stats: true
        },
        {
          name: "邀请赚钱",
          imgurl: require("@static/vip/jiagoufenxiao.png"),
          link: "/dis_entry",
          stats: true
        },
        {
          name: "大转盘",
          imgurl: require("@static/vip/zhuanpan.png"),
          link: "/turntable?entry_type=1",
          stats: true
        },
        
      ]
    };
  },
  created() {
    if (this.isWeixin_status) {
      goWxConfigJsApi("hideMenuItems");
      setTimeout(() => {
        wx.hideMenuItems({
          menuList: ['menuItem:copyUrl', 'menuItem:share:timeline', 'menuItem:share:appMessage'] 
        });
      }, 500);
      if (!sessionStorage.getItem("time")) {
        // 微信配置
        goWxConfigJsApi("openLocation,getLocation");
        setTimeout(() => {
          this.getInfo();
        }, 500);
      } else {
        let obj = JSON.parse(sessionStorage.getItem("now_store"));
        let res = JSON.parse(sessionStorage.getItem("store_all"));
        this.storeInfo = obj ? obj : res[0];
      }
    } else {
      this.getH5Info();
    }
  },
  methods: {
    goYouList() {
      this.$router.push("/vipShopList");
    },
    // 判断是否开通大转盘
    async getConfig() {
      try {
        const res = await Setting.roundConfig();
        if (res.code == 200) {
          if (res.data.length == 0) {
            return false;
          } else {
            return true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 判断是否是分销商
    async isDistributor() {
      try {
        const res = await UserInfo.getSpreadSetInfo();
        if (res.code == 200) {
          if (this.isWeixin_status) {
            this.$router.push("/invitation");
          } else {
            this.$toast("请在微信浏览器中打开");
          }
        } else {
          this.$router.push("/dis_entry");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async systemUpdate() {
      try {
        const res = await CreditShop.systemUpdate();
        if (res.type == 1) {
          if (res.app_id != localStorage.getItem("app_id")) {
            this.$router.replace("/beUpdating");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    setStore() {
      this.$router.push('/vipShopList')
    },
    go_sign_in() {
      this.$router.push("/integralshop");
    },
    goUserPay() {
      const { app_id, id, store_id } = this.storeInfo;
      const token= localStorage.getItem('access_token');
      const url = "https://xzf.58zltc.com/cardpay"+
        "?room_id="+id+"&store_id="+store_id+"&app_id="+app_id+"&app_token="+token;
      window.location.href = url;
      // localStorage.removeItem("qrcode_id");
      // this.$router.push({
      //   path: "/cardpay",
      //   query: {
      //     type: 0,
      //     store_id: this.storeInfo.store_id,
      //     room_id: this.storeInfo.id
      //   }
      // });
    },
    // 计算两地之间距离
    setDistance(shop_arr) {
      var _this = this;
      sessionStorage.setItem("time", new Date().getTime());
      wx.getLocation({
        type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function(res) {
          _this.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          _this.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          let arr = [];
          for (let i = 0; i < shop_arr.length; i++) {
            // 计算距离
            let item = shop_arr[i];
            item.dis = getDistance(
              item.location.lat,
              item.location.lng,
              res.latitude,
              res.longitude
            );
            arr.push(item);
          }
          const list = _this.quickSort(arr);
          _this.shopList = list;
          _this.storeInfo = list[0];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            _this.columns.push(element.name);
          }
          _this.$toast.clear();
          sessionStorage.setItem("store_all", JSON.stringify(list));
        },
        fail: function(err) {
          _this.getH5Info();
          _this.$toast.clear();
          console.log(err);
        },
        complete: function() {
          console.log("aaaa");
        }
      });
    },
    async getH5Info() {
      try {
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.storeInfo = res.data[0];
            this.shopList = res.data;
            // this.initStoreInfo(res.data);
            sessionStorage.setItem("store_all", JSON.stringify(res.data));
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getInfo() {
      try {
        this.my_msg = "获取店铺信息~";
        sessionStorage.setItem("time", new Date().getTime());
        const res = await CreditShop.shopList("", 1);
        if (res.code == 200) {
          if (res.data.length > 0) {
            if (res.data.length == 1) {
              this.storeInfo = res.data[0];
              this.shopList = res.data;
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                this.columns.push(element.name);
              }
              this.$toast.clear();
              sessionStorage.setItem("store_all", JSON.stringify(res.data));
            } else {
              this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                message: this.my_msg,
                loadingType: "spinner",
                selector: "#custom-selector"
              });
              this.setDistance(res.data);
            }
          }
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async skipPage(item) {
      this.$router.push(item.link);
      if (item.name == "买单") {
        const { app_id, id, store_id } = this.storeInfo;
        const token= localStorage.getItem('access_token');
        const url = "https://xzf.58zltc.com/cardpay"+
          "?room_id="+id+"&store_id="+store_id+"&app_id="+app_id+"&app_token="+token;
        window.location.href = url;
      } else if (item.name == "支付管理") {
        sessionStorage.setItem("path", `${this.$route.fullPath}`);
        sessionStorage.setItem("verifyType", "dealPassword");
        this.$router.push("/userSafety");
      } else if (item.name == "大转盘") {
        let status = await this.getConfig();
        if (!status) return this.$toast("尚未开通大转盘,请耐心等待~");
        this.$router.push(item.link);
      } else if (item.name == "邀请赚钱") {
        return this.isDistributor();
      }
    },
    know() {
      window.location.href =
        "https://mp.weixin.qq.com/s/rKg5vOlsWvd_ZmMljjTJ0w";
    }
  }
};
</script>
<style>
.jing-con {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.jingbiao-top-con {
  width: 100%;
  height: 50px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  opacity: .9;
  background: linear-gradient(180deg,rgba(59,111,255,1) 0%,rgba(59,111,255,1) 100%);
}
.jingbiao-store-title {
  width: 150px;
  display: inline-block;
  font-size: 13rpx;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jingbiao-top-con-center {
  width: 250px;
  display: flex;
  height: 40px;
  align-items: center;
}
.jingbiao-main-text {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: rgba(252, 243, 230, 1);
  justify-content: space-around;
}

.jingbiao-sign-in {
  width: 67px;
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  text-align: center;
  font-family: SourceHanSansCN;
  background: linear-gradient(
    0deg,
    rgba(236, 139, 24, 1),
    rgba(243, 183, 48, 1)
  );
  border-radius: 10px;
}

.jingbiao-banner-con {
  width: 100%;
  height: 193px;
  opacity: 0.9;
  z-index: 9999;
  border-radius:0px 0px 25px 25px;
  background: linear-gradient(180deg,rgba(59,111,255,1) 0%,rgba(59,111,255,1) 100%);
}

.jingbiao-swiper {
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  position: relative;
}
.logo {
  width: 61px;
  height: 23px;
  top: 10px;
  left: 20px;
  position: absolute;
}
.logo > img{
  width: 100%;
}
.jingbiao-swiper img {
  width: 100%;
  border-radius: 16rpx;
}

.jingbiao-store-info {
  width: 94%;
  height: auto;
  padding-top: 10px;
  margin: 10px auto 0;
  border-radius: 10px;
  position: relative;
  padding-bottom: 10px;
  /* z-index: -1; */
  background: url("https://zltc-1255680458.cos.ap-beijing.myqcloud.com/1590460117.png");
}
.jingbiao-store-info-con {
  width: 100%;
  height: 35px;
  display: flex;
  padding: 0 14px;
  align-items: center;
  justify-content: space-between;
}

.jingbiao-home-arr {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;
  justify-content: space-between;
}
.jingbiao-my-store {
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.jingbiao-left-logo {
  width: 82px;
  height: 57px;
}
.jingbiao-left-con {
  width: 100%;
  height: 160rpx;
  display: flex;
  margin-left: -10px;
  margin-top: 20px;
}

.jingbiao-left-con-item {
  flex: 1;
  height: 60px;
  display: flex;
  position: relative;
  margin-left: 11rpx;
  flex-direction: column;
  align-items: center;
  padding: 20rpx 0;
  /* border-right: 2rpx solid rgba(168,121,54,0.jingbiao-4); */
  justify-content: space-between;
}
.jingbiao-item-lable {
  padding: 3px 15px;
  background: #f3cc8d;
  display: inline-block;
  border-radius: 8px;
  font-size: 10px;
  margin-left: 7px;
}
.jingbiao-item-lable-bot {
  color: #fff1db;
  font-weight: 560;
}
.jingbiao-item-lable-bot text:nth-child(1) {
  font-size: 12px;
}
.jingbiao-item-lable-bot text:nth-child(2) {
  font-size: 20px;
}

.jingbiao-oil-title {
  font-size: 18px;
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-style: italic;
  color: rgba(249, 248, 248, 1);
  background: linear-gradient(
    149deg,
    rgba(255, 226, 179, 1) 0%,
    rgba(255, 240, 215, 1) 100%
  );
  -webkit-text-fill-color: transparent;
}
.jingbiao-line {
  width: 1px;
  height: 41px;
  position: absolute;
  right: 0;
  top: 16px;
  background: rgba(168, 121, 54, 0jingbiao-4);
}
.jingbiao-line:last-child {
  display: none;
}

.jingbiao-home-item {
  font-size: 12px;
  width: 70px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  margin: 5px;
  padding: 10px 5px;
  color: rgba(69, 69, 69, 1);
  font-size: 12px;
}
.jingbiao-logo-vip {
  width: 90px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  position: relative;
  border-radius: 20px;
  background: linear-gradient(90deg, rgb(244, 222, 182), rgb(235, 190, 135));
}
.jingbiao-logo-vip span {
  width: 100%;
  color: #efdcb1;
  z-index: 999;
  color: #40403f;
  display: inline-block;
  text-align: center;
}

.jingbiao-item-icon {
  width: 35px;
  height: 35px;
}
.jingbiao-item-icon img {
  width: 100%;
  height: 100%;
}

.jingbiao-fun-btn {
  width: 100%;
  padding: 0 30px;
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.jingbiao-fun-btn-item {
  width: 98%;
  display: flex;
  height: 50px;
  color: #472708;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: linear-gradient(90deg, rgb(244, 222, 182), rgb(235, 190, 135));
  box-shadow: 0px 4px 9px 0px rgba(68, 103, 241, 0.27);
  border-radius: 20px;
}
.jingbiao-fun-btn-item > div {
  width: 30px;
  height: 34px;
}
.jingbiao-fun-btn-item > div > img {
  width: 100%;
  height: 100%;
}
.jingbiao-fun-btn-item:nth-child(2) {
  background: linear-gradient(
    90deg,
    rgba(95, 124, 253, 1),
    rgba(62, 99, 239, 1)
  );
  box-shadow: 0px 4px 9px 0px rgba(58, 93, 255, 0.3);
}
.jingbiao-fun-btn-item span {
  margin-left: 10px;
  font-size: 16px;
}

.jingbiao-serve-con {
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding: 2px 0;
  background-color: #fff;
}
.jingbiao-my-title {
  margin-top: 20px;
  font-size: 17px;
  padding: 10x 25px;
  font-weight: bold;
  letter-spacing: 2rpx;
  color: #313134;
}

.jingbiao-my-title img {
  width: 20px;
  height: 17px;
  margin-left: 10px;
}

.page-bottom {
  width: 100%;
  height: 70px;
  display: flex;
  color: #999;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 50px;
}
.page-bottom div {
  background-color: #999;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 2.5px 5px;
  margin-top: 5px;
}
</style>